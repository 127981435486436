/* eslint-disable no-redeclare */
/* global Office */
import * as React from "react";
import App from "./App";
import { PersistGate } from "redux-persist/integration/react"; // Import PersistGate
import { store, persistor } from "../store/store"; // Import store and persistor
import { Provider } from "react-redux";
// import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { OfficeWrapper } from "./components/OfficeWrapper";

/* global document */

Office.onReady((info) => {
  if (info.host === Office.HostType.Word) {
    const rootElement = document.getElementById("root");
    const root = createRoot(rootElement);
    root.render(
      // <StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <OfficeWrapper>
            <App />
          </OfficeWrapper>
        </PersistGate>
      </Provider>
      // </StrictMode>
    );
  }
});
