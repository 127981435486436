import { configureStore } from "@reduxjs/toolkit";
import analysisReducer from "./analysisSlice";
import userReducer from "./userDataSlice";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Using localStorage by default

// Define the persist config
const persistConfig = {
  key: "root",
  storage, // You can also use sessionStorage by using 'redux-persist/lib/storage/session'
  whitelist: ["user"],
};

// Persist user reducer
const persistedUserReducer = persistReducer(persistConfig, userReducer);

const store = configureStore({
  reducer: {
    analysis: analysisReducer,
    user: persistedUserReducer,
  },
});

const persistor = persistStore(store); // Create persistor

// Export the store and persistor
export { store, persistor };

// Define the root state type
export type RootState = ReturnType<typeof store.getState>;
