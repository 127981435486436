import React, { useState, useEffect } from "react";
import loadingIcon from "../../../assets/loading.png";
import tickIcon from "../../../assets/tick.png";

const ProgressBar: React.FC = () => {
  const [uploadProgress, setUploadProgress] = useState(0);
  useEffect(() => {
    // eslint-disable-next-line no-undef
    const interval = setInterval(() => {
      setUploadProgress((prev) => (prev < 100 ? prev + 10 : prev));
    }, 1000);
    // eslint-disable-next-line no-undef
    return () => clearInterval(interval);
  }, []);
  return (
    <div
      style={{
        padding: "20px",
        fontFamily: "'Roboto', sans-serif",
        maxWidth: "600px",
        margin: "0 auto", // Center horizontally
      }}
    >
      {/* Heading and Description */}
      <h2
        style={{
          color: "#1F2937",
          fontSize: "14px",
          fontWeight: "500",
          fontStyle: "normal",
          marginBottom: "10px",
        }}
      >
        Processing Risk Analysis Text
      </h2>
      <p style={{ color: "#6B7280", fontSize: "12px", fontWeight: "400", fontStyle: "normal", marginBottom: "20px" }}>
        Please wait while LegalGraph AI analyzes the document and identifies risks.
      </p>

      {/* Progress Bar */}
      <div
        style={{
          backgroundColor: "#E5E7EB",
          height: "0.5rem",
          borderRadius: "0.25rem",
          overflow: "hidden",
          marginBottom: "20px",
        }}
      >
        <div
          style={{
            backgroundColor: "#3B82F6",
            height: "100%",
            width: `${uploadProgress}%`,
            transition: "width 0.3s ease-in-out",
          }}
        />
        {/* Percentage Text */}
        <div
          style={{
            textAlign: "center",
            color: "#1F2937",
            fontSize: "14px",
            fontWeight: "500",
          }}
        >
          {uploadProgress}%
        </div>
      </div>
      {/* Steps */}
      <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        {/* Analyzing Clauses */}
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <img
            src={uploadProgress >= 30 ? tickIcon : loadingIcon}
            alt="Step Icon"
            style={{ width: "16px", height: "16px" }}
          />
          <span
            style={{
              color: uploadProgress >= 30 ? "#1F2937" : "#9CA3AF",
              fontSize: "14px",
            }}
          >
            Analyzing Clauses
          </span>
        </div>

        {/* Identifying Risks */}
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <img
            src={uploadProgress >= 60 ? tickIcon : loadingIcon}
            alt="Step Icon"
            style={{ width: "16px", height: "16px" }}
          />
          <span
            style={{
              color: uploadProgress >= 60 ? "#1F2937" : "#9CA3AF",
              fontSize: "14px",
            }}
          >
            Identifying Risks
          </span>
        </div>

        {/* Adding Redlines & Comments */}
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <img
            src={uploadProgress >= 90 ? tickIcon : loadingIcon}
            alt="Step Icon"
            style={{ width: "16px", height: "16px" }}
          />
          <span
            style={{
              color: uploadProgress >= 90 ? "#1F2937" : "#9CA3AF",
              fontSize: "14px",
            }}
          >
            Creating Redlining Suggestions
          </span>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
