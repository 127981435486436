/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-undef */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { searchAndScroll, appendToDocument } from "../../services/document-service";
import { addCommentsToDoc, strikeOutAndReplaceInDoc } from "../../commands/commands";
import uparrowIcon from "../../../assets/upArrow.png";
import downarrowIcon from "../../../assets/downArrow.png";
import aiIcon from "../../../assets/AI Icons.png";
// import editIcon from "../../../assets/edit.png";
// import { MessageBar, MessageBarType } from "@fluentui/react";

interface ClauseBoxProps {
  clauses: {
    riskClauses: Array<any>;
    missingClauses: Array<any>;
    favClauses: Array<any>;
  };
  onClauseRedline: (clause: any) => void;
  onClauseReject: (clause: any) => void;
}

const ClauseBox: React.FC<ClauseBoxProps> = ({ clauses, onClauseRedline, onClauseReject }) => {
  const navigate = useNavigate();
  // const [error, setError] = useState<string | null>(null);
  const { riskClauses, missingClauses, favClauses } = clauses;
  const [openClauses, setOpenClauses] = useState<{
    risk: number | null;
    missing: number | null;
    fav: number | null;
  }>({
    risk: null,
    missing: null,
    fav: null,
  });

  //To toggle between categories
  const toggleClause = (section: "risk" | "missing" | "fav", index: number) => {
    setOpenClauses((prevState) => ({
      ...prevState,
      [section]: prevState[section] === index ? null : index,
    }));
  };

  //To locate the actualcontents on document,this will the strikeouttext in riskyclause and text in the favourable clause
  const locateKeyterm = (clause: any) => {
    searchAndScroll(clause.location);
  };

  //To redlining the clauses
  const saveRedlineForClause = async (clause: any) => {
    try {
      await strikeOutAndReplaceInDoc(clause.location, clause.updatedContext);
      await addCommentsToDoc(clause.location, clause.comment);
      onClauseRedline(clause);
      setOpenClauses((prev) => ({
        ...prev,
        risk: null,
      }));
    } catch (error) {
      console.error("Error while redlining:", error);
    }
  };

  // Adding missing clause at the bottom of the document
  const addMissingToContract = async (clause: any) => {
    try {
      // Cleaning the key of the clause
      const cleanKey = clause.key.replace(/ \(\d+\)$/, "");

      // Calling the append to document util function, and passing a clean key text
      await appendToDocument({
        heading: cleanKey,
        description: clause.standardAnswer,
        headingFontSize: 14,
        descriptionFontSize: 11,
        newPage: true,
      }).then(async () => {
        // Scrolling to the added missing
        searchAndScroll(clause.standardAnswer);
        await addCommentsToDoc(clause.standardAnswer, `This is our (${cleanKey}) clause.`);
      });
    } catch (err) {
      console.error("Error while adding missing:", err);
      return false;
    }
  };

  //To remove redline for clauses
  const undoRedlineForClause = async (location: string, clause: any) => {
    try {
      // Any cleanup operations needed for rejecting the clause
      onClauseReject(clause);
      setOpenClauses((prev) => ({
        ...prev,
        risk: null,
      }));
    } catch (error) {
      console.error("Error while rejecting clause:", error);
    }
  };
  // Function to clean the clause text by removing unwanted characters

  const cleanClauseText = (text: string) => {
    return text.replace(/ \(\d+\)$/, "");
    // return text.split("(")[0].trim(); // Get text before the first '('
  };

  return (
    <div style={{ flex: 1, overflowY: "auto", padding: "1rem" }}>
      {riskClauses && riskClauses.length > 0 && (
        <div style={{ marginBottom: "1rem" }}>
          <div
            style={{
              backgroundColor: "rgb(248, 113, 113)",
              padding: "1rem",
              borderRadius: "12px",
              display: "inline-block",
              // height: "24px",
              marginBottom: "15px",
            }}
          >
            <h3 style={{ color: "rgb(255 255 255)", margin: "-5px", fontSize: "12px", fontWeight: "500" }}>
              {riskClauses.length} Risks
            </h3>
          </div>
          {riskClauses.map((clause, index) => (
            <div key={index} style={{ marginBottom: "0.5rem" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  cursor: "pointer",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#08081A",
                  paddingBottom: "0.5rem",
                  borderBottom: "1px solid #ccc",
                }}
                onClick={() => {
                  toggleClause("risk", index);
                  locateKeyterm(clause);
                }}
              >
                <span>{cleanClauseText(clause.key)}</span>
                <img
                  src={openClauses.risk === index ? uparrowIcon : downarrowIcon}
                  alt={openClauses.risk === index ? "Up Arrow" : "Down Arrow"}
                  style={{ width: "16px", height: "16px" }}
                />
              </div>
              {openClauses.risk === index && (
                <div
                  style={{
                    backgroundColor: "#f5f5f5",
                    padding: "1rem",
                    borderRadius: "8px",
                    marginTop: "0.5rem",
                  }}
                  onClick={() => locateKeyterm(clause)}
                >
                  {/* Clause Heading with Icon and Edit */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "0.5rem",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
                      <span
                        style={{
                          display: "inline-block",
                          width: "10px",
                          height: "10px",
                          backgroundColor: "#EF4444",
                          borderRadius: "50%",
                        }}
                      ></span>
                      <h4
                        style={{
                          margin: 0,
                          fontSize: "14px",
                          fontWeight: "500",
                          fontStyle: "normal",
                          color: "#08081A",
                        }}
                      >
                        {cleanClauseText(clause.key)}
                      </h4>
                    </div>
                    {/* <img
                      src={editIcon}
                      alt="Edit Icon"
                      style={{
                        width: "24px",
                        height: "24px",
                        cursor: "pointer",
                        marginLeft: "60px",
                      }}
                      onClick={() => navigate("/edit", { state: { clause } })}
                    /> */}
                    <img
                      src={aiIcon}
                      alt="Reasoning Icon"
                      style={{
                        width: "24px",
                        height: "24px",
                        cursor: "pointer",
                      }}
                      onClick={() => navigate("/reasoning", { state: { clause } })}
                    />
                  </div>

                  {/* Clause Details */}
                  <p
                    style={{
                      margin: 0,
                      fontSize: "14px",
                      fontWeight: "500",
                      fontStyle: "normal",
                      lineHeight: "1.5",
                      textAlign: "left",
                      color: "#166534",
                    }}
                  >
                    {clause.updatedContext}
                  </p>
                  <p
                    style={{
                      margin: 0,
                      fontSize: "14px",
                      fontWeight: "500",
                      fontStyle: "normal",
                      lineHeight: "1.5",
                      textAlign: "left",
                      color: "#424165",
                      textDecoration: "line-through",
                    }}
                  >
                    {clause.location}
                  </p>

                  {/* Buttons */}
                  <div
                    style={{
                      display: "flex",
                      gap: "0.5rem",
                      marginTop: "1rem",
                    }}
                  >
                    <button
                      style={{
                        padding: "0.5rem 1rem",
                        backgroundColor: "#3E51EE",
                        border: "none",
                        borderRadius: "6px",
                        fontSize: "14px",
                        fontWeight: "500",
                        cursor: "pointer",
                        color: "white",
                      }}
                      onClick={() => saveRedlineForClause(clause)}
                    >
                      Save Redline
                    </button>
                    <button
                      style={{
                        padding: "0.5rem 1rem",
                        backgroundColor: "#ffffff",
                        border: "#3E51EE",
                        borderRadius: "6px",
                        fontSize: "14px",
                        fontWeight: "500",
                        cursor: "pointer",
                      }}
                      onClick={() => undoRedlineForClause(clause.location, clause)}
                    >
                      Ignore
                    </button>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      )}

      {/* Conditional Rendering for Missing Clauses */}
      {missingClauses && missingClauses.length > 0 && (
        <div style={{ marginBottom: "1rem" }}>
          <div
            style={{
              backgroundColor: "rgb(251, 146, 60)",
              padding: "1rem",
              borderRadius: "12px",
              display: "inline-block",
              // height: "24px",
              marginBottom: "15px",
            }}
          >
            <h3 style={{ color: "rgb(255 255 255)", margin: "-5px", fontSize: "12px", fontWeight: "500" }}>
              {missingClauses.length} Missing Clauses
            </h3>
          </div>
          {missingClauses.map((clause, index) => (
            <div key={index} style={{ marginBottom: "0.5rem" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  cursor: "pointer",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#08081A",
                  paddingBottom: "0.5rem",
                  borderBottom: "1px solid #ccc",
                }}
                onClick={() => toggleClause("missing", index)}
              >
                <span>{cleanClauseText(clause.key)}</span>
                <img
                  src={openClauses.missing === index ? uparrowIcon : downarrowIcon}
                  alt={openClauses.missing === index ? "Up Arrow" : "Down Arrow"}
                  style={{ width: "16px", height: "16px" }}
                />
              </div>
              {openClauses.missing === index && (
                <div
                  style={{
                    backgroundColor: "#f5f5f5",
                    padding: "1rem",
                    borderRadius: "8px",
                    marginTop: "0.5rem",
                  }}
                >
                  {/* Clause Heading with Icon and Edit */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      marginBottom: "0.5rem",
                    }}
                  >
                    <span
                      style={{
                        display: "inline-block",
                        width: "74px",
                        height: "10px",
                        backgroundColor: "#EF4444",
                        borderRadius: "50%",
                        marginTop: "4px", // Aligns the dot with the first line
                      }}
                    ></span>
                    <div style={{ marginLeft: "0.5rem" }}>
                      <h4
                        style={{
                          margin: 0,
                          fontSize: "14px",
                          fontWeight: "500",
                          fontStyle: "normal",
                          color: "#08081A",
                        }}
                      >
                        {cleanClauseText(clause.key)}
                      </h4>
                      <p
                        style={{
                          marginTop: "0.5rem",
                          fontSize: "14px",
                          fontWeight: "500",
                          fontStyle: "normal",
                          lineHeight: "1.5",
                          textAlign: "left",
                          color: "#166534",
                        }}
                      >
                        {clause.standardAnswer}
                      </p>
                    </div>
                  </div>

                  {/* Clause Details */}
                  {/* <p
                    style={{
                      margin: 0,
                      fontSize: "14px",
                      fontWeight: "500",
                      fontStyle: "normal",
                      lineHeight: "1.5",
                      textAlign: "left",
                      color: "#4B4B4B",
                    }}
                  >
                    {clause.analysis}
                  </p> */}

                  {/* Buttons */}
                  <div
                    style={{
                      display: "flex",
                      gap: "0.5rem",
                      marginTop: "1rem",
                    }}
                  >
                    <button
                      style={{
                        padding: "0.5rem 1rem",
                        backgroundColor: "#3E51EE",
                        border: "none",
                        borderRadius: "6px",
                        fontSize: "14px",
                        fontWeight: "500",
                        cursor: "pointer",
                        color: "white",
                      }}
                      onClick={() => addMissingToContract(clause)}
                    >
                      Accept
                    </button>
                    <button
                      style={{
                        padding: "0.5rem 1rem",
                        backgroundColor: "#ffffff",
                        border: "#3E51EE",
                        borderRadius: "6px",
                        fontSize: "14px",
                        fontWeight: "500",
                        cursor: "pointer",
                      }}
                    >
                      Decline
                    </button>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      )}

      {/* Conditional Rendering for Fav Clauses */}
      {favClauses && favClauses.length > 0 && (
        <div style={{ marginBottom: "1rem" }}>
          <div
            style={{
              backgroundColor: "#DCFCE7",
              padding: "1rem",
              borderRadius: "12px",
              display: "inline-block",
              height: "24px",
              marginBottom: "15px",
            }}
          >
            <h3 style={{ color: "rgb(255 255 255)", margin: "-5px", fontSize: "12px", fontWeight: "500" }}>
              {favClauses.length} Favourable
            </h3>
          </div>
          {favClauses.map((clause, index) => (
            <div key={index} style={{ marginBottom: "0.5rem" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  cursor: "pointer",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#08081A",
                  paddingBottom: "0.5rem",
                  borderBottom: "1px solid #ccc",
                }}
                onClick={() => toggleClause("fav", index)}
              >
                <span>{cleanClauseText(clause.key)}</span>
                <img
                  src={openClauses.fav === index ? uparrowIcon : downarrowIcon}
                  alt={openClauses.fav === index ? "Up Arrow" : "Down Arrow"}
                  style={{ width: "16px", height: "16px" }}
                />
              </div>
              {openClauses.fav === index && (
                <div
                  style={{
                    backgroundColor: "#f5f5f5",
                    padding: "1rem",
                    borderRadius: "8px",
                    marginTop: "0.5rem",
                  }}
                  onClick={() => locateKeyterm(clause)}
                >
                  {/* Clause Heading with Icon and Edit */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "0.5rem",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
                      <span
                        style={{
                          display: "inline-block",
                          width: "10px",
                          height: "10px",
                          backgroundColor: "#166534",
                          borderRadius: "50%",
                        }}
                      ></span>
                      <h4
                        style={{
                          margin: 0,
                          fontSize: "14px",
                          fontWeight: "500",
                          fontStyle: "normal",
                          color: "#08081A",
                        }}
                      >
                        {cleanClauseText(clause.key)}
                      </h4>
                    </div>
                    {/* <img
                      src={editIcon}
                      alt="Edit Icon"
                      style={{
                        width: "24px",
                        height: "24px",
                        cursor: "pointer",
                        marginLeft: "60px",
                      }}
                      onClick={() => navigate("/edit", { state: { clause } })}
                    /> */}
                    <img
                      src={aiIcon}
                      alt="Reasoning Icon"
                      style={{
                        width: "24px",
                        height: "24px",
                        cursor: "pointer",
                      }}
                      onClick={() => navigate("/reasoning", { state: { clause } })}
                    />
                  </div>

                  {/* Clause Details */}
                  <p
                    style={{
                      margin: 0,
                      fontSize: "14px",
                      fontWeight: "500",
                      fontStyle: "normal",
                      lineHeight: "1.5",
                      textAlign: "left",
                      color: "#4B4B4B",
                    }}
                  >
                    {clause.location}
                  </p>

                  {/* Buttons */}
                  <div
                    style={{
                      display: "flex",
                      gap: "0.5rem",
                      marginTop: "1rem",
                    }}
                  >
                    <button
                      style={{
                        padding: "0.5rem 1rem",
                        backgroundColor: "#3E51EE",
                        border: "none",
                        borderRadius: "6px",
                        fontSize: "14px",
                        fontWeight: "500",
                        cursor: "pointer",
                        color: "white",
                      }}
                    >
                      Add to Risk & Redline
                    </button>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ClauseBox;
