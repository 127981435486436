/* eslint-disable */
/* global Office Word*/

let reTryCount = 0;
const reTryLimit = 1;

/**
 * Function to get current open document's file name.
 * @returns String on success and null on failure.
 */
async function getDocumentMetadata(): Promise<string | null> {
  try {
    return new Promise<string | null>((resolve) => {
      // Using Office's onReady function to get all the context and them pull out all the file properties.
      Office.onReady(() => {
        Office.context.document.getFilePropertiesAsync((result) => {
          if (result.status === Office.AsyncResultStatus.Succeeded) {
            // Extracting only file name using split() from the complete path.
            const fileName = result.value.url.split("\\").pop() || null;

            // Resolving the promise here so that other function can directly await it.
            resolve(fileName);
          } else {
            resolve(null);
          }
        });
      });
    });
  } catch (error) {

  }
}

/**
 * Function to get all the pages with content from the current open file.
 * @returns Object on success and null on failure.
 */
async function getPageData(): Promise<object | null> {
  try {
    return new Promise<string[] | null>((resolve) => {
      Word.run(async (context) => {
        const document = context.document;
        const paragraphs = document.body.paragraphs;
        paragraphs.load("items");
        await context.sync();

        const contentArray = paragraphs.items.map((p) => p.text);
        resolve(contentArray);
      });
    });
  } catch (error) {
    console.error("Error getting document's content");
  }
}

/**
 * Function to add content at the end of the page.
 * @param heading: Heading of the content that you want to add at the end of the page.
 * @param description: Description/actual text that will be added under this heading.
 * @returns Object on success and null on failure.
 */
async function appendToDocument(textContent: any) {
  try {
        await Word.run(async (context) => {
            // Get the body of the document
            const body = context.document.body;

            if (textContent.newPage) {
              // Insert a page break at the end
              body.insertBreak(Word.BreakType.page, Word.InsertLocation.end);
            }

            // Create a new paragraph for the heading
            const headingParagraph = body.insertParagraph(
                textContent.heading, 
                Word.InsertLocation.end
            );
            headingParagraph.font.bold = true;
            headingParagraph.font.size = textContent.headingFontSize;

            // Create a paragraph for the description
            const descriptionParagraph = body.insertParagraph(
                textContent.description, 
                Word.InsertLocation.end
            );
            descriptionParagraph.font.bold = false;
            descriptionParagraph.font.size = textContent.descriptionFontSize;

            // Add a line break after description
            body.insertParagraph("", Word.InsertLocation.end);

            // Synchronize the document state
            await context.sync();
        });
    } catch (error) {
        console.error('Error appending to document:', error);
    }
}



/**
 * Function to search a string in a document and scroll to it.
 * @param searchString: The actual string present in the document that needs to be searched.
 * @returns Object on success and null on failure.
 */
async function searchAndScroll(searchString: string) {
  try {
    // Handling Word's 255 character search limit
    if (searchString.length >= 255) {
        searchString = searchString.slice(0, 250);
    }

    // Removing unnecessary spaces from the start and end of the string.
    searchString = searchString.trim();

    await Word.run(async (context) => {
      // Getting the complete content of the current open document or content
      const body = context.document.body;

      // Using the native .search() method to search within a string.
      const searchResults = body.search(
        searchString,
        {
          matchCase: false,
          matchWholeWord: false,
          ignoreSpace: true,
          ignorePunct: false,
          matchPrefix: false,
          matchSuffix: false,
          matchWildcards: false
        }
      );

      // Loading the search results
      context.load(searchResults, "text");
      await context.sync();

      if (searchResults.items.length > 0) {
        /**
         * As there can be multiple occurrences of a particular string in the document, here we are only
         * adding a comment to the very first occurrence of the searched string.
        */
        const firstMatch = searchResults.items[0];

        // Selecting the range of the text and scrolling into the view.
        firstMatch.select(); 

        await context.sync();
        return true;
      } else {

        // Trying to split the first ten characters of the not found string, to try to find it.
        if (reTryCount < reTryLimit) {
            // Incrementing retry counter by 1 for number of re-tries.
            reTryCount +=1;
            
            // Fine-tune this value to slice off first 20 characters form a string
            searchString = searchString.slice(20); 
            
            await searchAndScroll(searchString);
          }
          // Resetting reTry to 0.
          reTryCount = (reTryCount == reTryLimit) ? 0 : reTryCount

        return false;
      }
    });
    return true;
  } catch (error) {
    return false;
  }
}

// Exporting all the functions from this file.
export { getDocumentMetadata, getPageData, searchAndScroll, appendToDocument };
