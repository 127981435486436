/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import { MemoryRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import HomePage from "./pages/HomePage";
import Login from "../taskpane/pages/Login/Login";
import RiskAnalyse from "./pages/RiskAnalyse/RiskAnalyse";
import UploadPage from "./pages/UploadPage";
import EditClauseTerm from "./pages/EditClauseTerm";
import ReasoningPage from "./pages/ReasoningPage";
import { Signup } from "./pages/Signup/Signup";

const App: React.FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false); //tracks whether the user is logged in.
  useEffect(() => {
    // Check if token exists in localStorage to persist login
    const token = localStorage.getItem("authToken");
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("activeUserId");
    setIsAuthenticated(false);
  };

  return (
    <Router>
      {!isAuthenticated ? (
        <Routes>
          <Route path="*" element={<Login onLogin={() => setIsAuthenticated(true)} />} />
          <Route path="/signup" element={<Signup onSignup={() => setIsAuthenticated(true)} />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="/" element={<HomePage onLogout={handleLogout} />} />
          <Route path="/upload" element={<UploadPage onLogout={handleLogout} />} />
          <Route path="/analyze" element={<RiskAnalyse onLogout={handleLogout} />} />
          <Route path="/edit" element={<EditClauseTerm onLogout={handleLogout} />} />
          <Route path="/reasoning" element={<ReasoningPage onLogout={handleLogout} />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      )}
    </Router>
  );
};

export default App;
