/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-unescaped-entities */
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { jwtDecode } from "jwt-decode";
import { HandleLogin } from "../../../services/userAuth-services";
import { logoutUser, setUser } from "../../../store/userDataSlice";
import { Link, useNavigate } from "react-router-dom";
import { Alert } from "@mui/material";

interface LoginProps {
  onLogin: () => void;
}
interface CustomJwtPayload {
  email: string;
  username: string;
  userId: string;
  exp: number;
}

const Login: React.FC<LoginProps> = ({ onLogin }) => {
  const dispatch = useDispatch();
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = React.useState(false); // State to track loading
  const [showAlert, setShowAlert] = useState(false); // State to control alert visibility

  const navigate = useNavigate();

  const isTokenExpired = (expirationTime: number): boolean => {
    const currentTime = Date.now(); // Current time in milliseconds
    const expirationTimeMs = expirationTime * 1000; // Convert expirationTime from seconds to milliseconds
    return currentTime >= expirationTimeMs; // If current time is greater than or equal to expiration time, the token is expired
  };
  const handleAutoLogout = (expirationTime: number) => {
    if (isTokenExpired(expirationTime)) {
      // Perform logout actions
      localStorage.removeItem("authToken");
      localStorage.removeItem("activeUserId");
      localStorage.clear();
      dispatch(logoutUser()); // Clear user data in Redux store
      setShowAlert(true); // Show alert
      setTimeout(() => {
        setShowAlert(false); // Hide alert after 3 seconds
        navigate("/");
      }, 3000);
    } else {
      console.log("Token is still valid.");
    }
  };

  //using formik library and yup validating form before sending to backend.
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email format").required("Email is required"),
      password: Yup.string().required("Password is required"),
    }),
    onSubmit: async (values) => {
      setError(null); // Reset error state before login attempt
      setIsLoading(true); // Show loading text
      try {
        const response = await HandleLogin(values.email, values.password);
        if (response?.status === 200) {
          const { token } = response.data;
          const decodedToken = jwtDecode<CustomJwtPayload>(token); // Decode the token using jwtdecode
          if (!decodedToken) {
            throw new Error("Failed to decode token");
          }
          const { email, username, userId, exp } = decodedToken;
          dispatch(
            setUser({
              email: decodedToken.email,
              username: decodedToken.username,
              userId: decodedToken.userId,
              token: token,
            })
          );
          localStorage.setItem("authToken", token);
          localStorage.setItem("activeUserId", userId);
          onLogin();
          setIsLoading(false); // Reset loading text
          // Trigger auto-logout based on the expiration time
          handleAutoLogout(exp);
        } else {
          setError(response?.data?.message || "Invalid login credentials");
          setIsLoading(false); // Reset loading text
        }
      } catch (err) {
        setError(err.response?.data?.message || "An error occurred during login. Please try again.");
        setIsLoading(false); // Reset loading text
      }
    },
  });

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#ffffff",
      }}
    >
      <div
        style={{
          padding: "2rem",
          width: "100%",
          maxWidth: "400px",
          border: "1px solid #ddd",
          borderRadius: "10px",
        }}
      >
        <div style={{ textAlign: "center", marginBottom: "20px" }}>
          {/* <img src="path_to_logo.png" alt="LegalGraph Logo" style={{ width: "50px", height: "50px" }} /> */}
          <p style={{ fontSize: "18px", fontWeight: "bold" }}>Welcome to LegalGraph AI</p>
        </div>
        <p
          style={{
            fontSize: "13px",
            fontWeight: 400,
            lineHeight: "14.32px",
            letterSpacing: "0.005em",
            fontFamily: "'SF Pro', sans-serif",
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          To start analyzing contracts, Please Log In to your LegalGraph AI account.
        </p>

        {/* Error message */}
        {error && (
          <p
            style={{
              color: "red",
              fontSize: "12px",
              textAlign: "center",
              marginBottom: "10px",
            }}
          >
            {error}
          </p>
        )}

        {showAlert && <Alert severity="warning">Session expired. You have been logged out.</Alert>}
        <form onSubmit={formik.handleSubmit}>
          {/* Email Input */}
          <input
            name="email"
            type="email"
            placeholder="Enter Your Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            style={{
              width: "100%",
              padding: "10px",
              marginBottom: "10px",
              fontSize: "12px",
              borderRadius: "4px",
              border: "1px solid #ddd",
              fontFamily: "'SF Pro', sans-serif",
            }}
          />
          {formik.touched.email && formik.errors.email && (
            <p style={{ color: "red", fontSize: "12px" }}>{formik.errors.email}</p>
          )}

          {/* Password Input */}
          <input
            name="password"
            type="password"
            placeholder="Enter Your Password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            style={{
              width: "100%",
              padding: "10px",
              marginBottom: "10px",
              fontSize: "12px",
              borderRadius: "4px",
              border: "1px solid #ddd",
              fontFamily: "'SF Pro', sans-serif",
            }}
          />
          {formik.touched.password && formik.errors.password && (
            <p style={{ color: "red", fontSize: "12px" }}>{formik.errors.password}</p>
          )}

          {/* Remember Me and Forgot Password */}
          {/* <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <div></div>
            <a
              style={{
                fontSize: "12px",
                color: "#000000",
                fontFamily: "'SF Pro', sans-serif",
              }}
            >
              <Link to="/resetpw" style={{ textDecoration: "none", color: "inherit" }}>
                Forgot Password?
              </Link>
            </a>
          </div> */}

          {/* Login Button */}
          <button
            type="submit"
            style={{
              display: "block",
              width: "100%",
              height: "40px",
              padding: "0.5rem",
              marginBottom: "20px",
              backgroundColor: "#d9d9d9",
              fontSize: "12px",
              fontFamily: "'SF Pro', sans-serif",
              fontWeight: 500,
              lineHeight: "14.32px",
              letterSpacing: "0.005em",
              textAlign: "center",
              border: "none",
              cursor: "pointer",
            }}
            disabled={isLoading || !formik.values.email || !formik.values.password}
          >
            {isLoading ? "Loading..." : "Login to LegalGraph AI"}
          </button>
        </form>

        <p
          style={{
            fontFamily: "'SF Pro', sans-serif",
            fontSize: "12px",
            fontWeight: 400,
            lineHeight: "14.32px",
            letterSpacing: "0.005em",
            textAlign: "center",
            textDecoration: "none",
          }}
        >
          Don't have an account?
          <Link to="/signup" style={{ color: "#000000", paddingLeft: "5px", textDecoration: "underline" }}>
            Sign up.
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Login;
