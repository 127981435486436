import React from "react";
import { useNavigate } from "react-router-dom";
import SidebarMain from "../components/SidebarMain";
// import SecureConnectionInfo from "../components/SecureConnectionInfo";
import UploadPlaybook from "../components/UploadPlaybook";
import backIcon from "../../../assets/back.png";

interface UploadPageProps {
  onLogout: () => void;
}

const UploadPage: React.FC<UploadPageProps> = ({ onLogout }) => {
  const navigate = useNavigate();
  return (
    <div style={{ display: "flex", minHeight: "100vh" }}>
      <SidebarMain onLogout={onLogout} />
      <div
        style={{
          flexGrow: 1,
          padding: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            marginBottom: "20px",
          }}
          onClick={() => navigate("/")}
        >
          <img src={backIcon} alt="Back" style={{ width: "24px", height: "24px", marginRight: "10px" }} />
          <span style={{ fontSize: "14px", fontWeight: "400", fontStyle: "normal", color: "#333" }}>Back</span>
        </div>

        {/* <SecureConnectionInfo /> */}
        <UploadPlaybook />
      </div>
    </div>
  );
};

export default UploadPage;
