import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ClauseCategory, AvailableClauses } from "../models/analysisData";
import { AnalysisPayload } from "../models/analysisData";

// Define the type for the state
interface AnalysisState {
  riskData: ClauseCategory[]; // Array of ClauseCategory
  availableClauses: AvailableClauses; // Object of AvailableClauses
  isLoading: boolean;
  // errorMessage: string | null;
  activeData: AnalysisPayload; //analyis api returned values for fileid,playbookid
  analysisPayload: AnalysisPayload;
}

// Define the initial state using the correct structure
const initialState: AnalysisState = {
  riskData: [],
  availableClauses: { riskClauses: [], missingClauses: [], favClauses: [] },
  isLoading: false,
  // errorMessage: null,
  activeData: {
    userId: null,
    playbookId: null,
    fileId: null,
  },
  analysisPayload: {
    userId: null,
    playbookId: null,
    fileId: null,
  },
};

const analysisSlice = createSlice({
  name: "analysis",
  initialState,
  reducers: {
    setRedlineHeader: (state, action: PayloadAction<ClauseCategory[]>) => {
      state.riskData = action.payload;
    },
    setCategorywiseClauses: (state, action: PayloadAction<AvailableClauses>) => {
      state.availableClauses = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    // setErrorMessage: (state, action: PayloadAction<string | null>) => {
    //   state.errorMessage = action.payload;
    // },
    setActiveData: (
      state,
      action: PayloadAction<{
        playbookId: string | null;
        fileId: string | null;
      }>
    ) => {
      const { playbookId, fileId } = action.payload;
      state.activeData = { ...state.activeData, playbookId, fileId };
    },
    setAnalysisPayload: (
      state,
      action: PayloadAction<AnalysisPayload> // Directly use the ActiveData type
    ) => {
      const { userId, playbookId, fileId } = action.payload;
      state.analysisPayload = { userId, playbookId, fileId };
    },
    setAnalysisPayloadFileId: (state, action: PayloadAction<Partial<AnalysisPayload>>) => {
      const { playbookId } = action.payload;
      state.analysisPayload = {
        ...state.analysisPayload, // Preserve existing properties
        playbookId,
      };
    },
    setAnalysisPayloadPlaybook: (state, action: PayloadAction<Partial<AnalysisPayload>>) => {
      const { playbookId } = action.payload;
      state.analysisPayload = {
        ...state.analysisPayload, // Preserve existing properties
        playbookId, // Update only playbookId
      };
    },
    // Action to clear all the data from redux store
    clearAnalysisData: (state) => {
      state.riskData = null;
      state.availableClauses = null;
      state.activeData = null;
      state.analysisPayload = null;
    },
  },
});

export const {
  setRedlineHeader,
  setCategorywiseClauses,
  setIsLoading,
  setActiveData,
  setAnalysisPayload,
  setAnalysisPayloadPlaybook,
  setAnalysisPayloadFileId,
  clearAnalysisData,
} = analysisSlice.actions;

export default analysisSlice.reducer;
