/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import SidebarMain from "../components/SidebarMain";
import Dropdown from "../components/Dropdown";
// import infoIcon from "../../../assets/infoicon.png";
// import editIcon from "../../../assets/edit.png";
import { getDocumentMetadata, getPageData } from "../../services/document-service";
import { uploadContentAsPdf } from "../../services/upload-to-backend";
import { getUserPlaybooks, switchPlaybook } from "../../services/playbook-service";
import { MessageBar, MessageBarType } from "@fluentui/react";
import * as dotenv from "dotenv";
dotenv.config();
import axios from "axios";
const apiUrl = process.env.API_URL;

interface HomePageProps {
  onLogout: () => void;
}

const HomePage: React.FC<HomePageProps> = ({ onLogout }) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState<string | null>(null);
  const [playbooks, setPlaybooks] = useState<any[]>([]);
  const [activePlaybook, setActivePlaybook] = useState<any | null>(null);
  const [selectedPlaybookId, setSelectedPlaybookId] = useState<string>("");
  const [autoactivatePlaybookId, setAutoactivatePlaybookId] = useState<string | null>(null);
  const [contractType, setContractType] = useState<string>("");
  const [contractTypeCode, setContractTypeCode] = useState<string>("");
  const storedUserId = localStorage.getItem("activeUserId");
  const [dropdownOptions, setDropdownOptions] = useState([{ label: "Loading...", value: "" }]);

  //when component mounts checks for word document is open or not,
  useEffect(() => {
    const checkWordDocumentOpen = async () => {
      if (Office.context.document) {
        await Word.run(async (context) => {
          const docBody = context.document.body;
          docBody.load("text");
          await context.sync();

          if (docBody.text.length === 0) {
            setError("No document content detected. Please open a contract in Word to start analysis.");
          } else {
            loadPlaybooks(storedUserId); // Load playbooks only if document is open and has content
            detectContractType(); // Detect contract type
          }
        }).catch(() => {
          setError("Failed to access the Word document. Please ensure a document is open.");
        });
      } else {
        setError("Word Add-In is not loaded or no document is open.");
      }
    };

    if (storedUserId) {
      checkWordDocumentOpen();
    }
  }, [storedUserId]);

  // To load the all playbooks for currently loggedin user
  const loadPlaybooks = async (userId: string) => {
    try {
      const playbooksData = await getUserPlaybooks(userId);
      setPlaybooks(playbooksData);
      const active = playbooksData.find((p) => p.active);
      if (active && !selectedPlaybookId) {
        setActivePlaybook(active);
        setSelectedPlaybookId(active.id);
      }
    } catch (error) {
      console.error("Error loading playbooks:", error);
    }
  };
  // const sortedPlaybooks = [...playbooks].sort((a, b) => {
  //   if (a.active) return -1;
  //   if (b.active) return 1;
  //   return a.playbookName.localeCompare(b.playbookName);
  // });

  const sortedPlaybooks = playbooks
    .filter((playbook) => playbook.contractType === contractTypeCode) // Filter by contractTypeCode
    .sort((a, b) => {
      // First, prioritize active playbooks
      if (a.active && !b.active) return -1;
      if (!a.active && b.active) return 1;

      // If both have the same 'active' status, sort by playbookName
      return a.playbookName.localeCompare(b.playbookName);
    });

  // Detect contract type by reading document content
  const detectContractType = async () => {
    try {
      setIsLoading(true);
      const fileName = await getDocumentMetadata();
      const textContent = await getPageData();
      const response = await uploadContentAsPdf(textContent, fileName);
      // if response suucessful calling autoactivate playbook api
      if (response.isSuccessful && response.fileIds?.length > 0) {
        const detectedfileId = response.fileIds[0]?.fileId;
        const detectedName = response.fileIds[0]?.name;
        const detectedCode = response.fileIds[0]?.code;
        const fileIds = response.fileIds.map((file) => ({
          label: file.name, // Display name as label
          value: file.code, // Use code as value
        }));
        setDropdownOptions(fileIds);
        setContractType(detectedName);
        setContractTypeCode(detectedCode);
        await activateAutoPlaybook(detectedCode);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setError("Failed to detect contract type.");
    }
  };
  // Function to activate auto-playbook
  const activateAutoPlaybook = async (contractTypeCode: string) => {
    const payload = {
      userId: storedUserId,
      contractTypeCode,
    };

    try {
      setIsLoading(true);
      const response = await axios.post(apiUrl + "/api/v1/add-in/auto-playbook-activation", payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const activatedPlaybookId = response.data.activePlaybookId;
      setSelectedPlaybookId(activatedPlaybookId);
      setAutoactivatePlaybookId(activatedPlaybookId);
      loadPlaybooks(storedUserId);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error making API call:", error);
    }
  };

  const partySide = [
    { label: "customer", value: "customer" },
    { label: "Service Provider", value: "serviceprovider" },
  ];
  const handlepartySideChange = (value: string) => {};

  //to select the playbook from dropdown
  const handlePlaybookSelect = async (value: string) => {
    try {
      setSelectedPlaybookId(value);
      // Check if playbook needs to be switched
      setIsLoading(true);
      if (activePlaybook && value !== activePlaybook.playbookId) {
        const switchResponse = await switchPlaybook(storedUserId, value);
        if (switchResponse) {
          setActivePlaybook({ playbookId: value }); // Update active playbook state
        } else {
          setError("Failed to switch playbook.");
        }
      }
      setIsLoading(false);
    } catch (error) {
      setError("An unexpected error occurred while switching playbooks. Please try again later.");
    }
  };

  //to trigger the riskanalysis button action
  const triggerRiskAnalysis = async () => {
    try {
      // Check if the Word document is open and has content
      if (Office.context.document) {
        await Word.run(async (context) => {
          const docBody = context.document.body;
          docBody.load("text");
          await context.sync();

          if (docBody.text.length > 0) {
            navigate("/analyze"); //if document is opened only, navigate to analysis page
          } else {
            setError("Please open a contract in Word to start analysis.");
          }
        });
      } else {
        setError("No Word document is open. Please open a contract in Word to start analysis.");
      }
    } catch (error) {
      setError("An unexpected error occurred. Please try again later.");
    }
  };

  return (
    <div style={{ display: "flex", minHeight: "100vh" }}>
      <SidebarMain onLogout={onLogout} />
      <div
        style={{
          flexGrow: 1,
        }}
      >
        <div
          style={{
            width: "300px",
            padding: "20px",
          }}
        >
          <h3
            style={{
              fontSize: "14px",
              marginBottom: "20px",
              color: "#333",
              textAlign: "left",
              lineHeight: "1.4",
              fontWeight: "600",
            }}
          >
            Please Confirm Your Settings to Start Risk Analysis
          </h3>
          {/* Contract Type */}
          <div style={{ marginBottom: "16px" }}>
            <label
              htmlFor="contractType"
              style={{
                display: "block",
                marginBottom: "8px",
                fontSize: "14px",
                fontWeight: "500",
                color: "#555",
              }}
            >
              Contract Type
            </label>
            <Dropdown
              options={dropdownOptions}
              onSelect={(value) => setContractType(value)}
              value={isLoading ? "Loading..." : contractType}
            />
          </div>
          {/* Party Side */}
          {/* <div style={{ marginBottom: "16px" }}>
            <label
              htmlFor="partySide"
              style={{
                display: "block",
                marginBottom: "8px",
                fontSize: "14px",
                fontWeight: "500",
                color: "#555",
              }}
            >
              Select Party Side
            </label>
            <Dropdown options={partySide} onSelect={handlepartySideChange} />
          </div> */}
          {/* Standard Document */}
          <div style={{ marginBottom: "16px" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <label
                htmlFor="playbook-select"
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#555",
                }}
              >
                Select Playbook
              </label>
            </div>
            {isLoading && (
              <Dropdown
                options={[{ label: "Loading...", value: "" }]}
                onSelect={handlePlaybookSelect}
                isDefault={selectedPlaybookId === autoactivatePlaybookId}
                value={selectedPlaybookId}
              />
            )}

            {!isLoading && (
              <Dropdown
                options={sortedPlaybooks.map((playbook) => ({
                  label: isLoading ? "Loading..." : playbook.playbookName,
                  value: playbook.playbookId,
                }))}
                onSelect={handlePlaybookSelect}
                isDefault={selectedPlaybookId === autoactivatePlaybookId}
                value={selectedPlaybookId}
              />
            )}
          </div>
          {/* Buttons */}
          <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <button
              style={{
                width: "100%",
                padding: "10px",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                backgroundColor: "#f5f5f5",
                color: "#1f2937",
                border: "none",
                borderRadius: "6px",
                cursor: "pointer",
              }}
              onClick={() => navigate("/upload")}
            >
              Upload New Playbook
            </button>

            <button
              style={{
                width: "100%",
                padding: "10px",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                backgroundColor: "#3E51EE",
                color: "#fff",
                border: "none",
                borderRadius: "6px",
                cursor: "pointer",
              }}
              // disabled={!storedPlaybookId}
              disabled={isLoading}
              onClick={() => triggerRiskAnalysis()}
            >
              {isLoading ? "Loading..." : "Start Analysis"}
            </button>
          </div>
          {/* Show error message if file type is incorrect */}
          {error && (
            <MessageBar messageBarType={MessageBarType.warning} isMultiline={false} dismissButtonAriaLabel="Close">
              {error}
            </MessageBar>
          )}
        </div>
      </div>
    </div>
  );
};

export default HomePage;
