/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import arrowUp from "../../../assets/upArrow.png";
import arrowDown from "../../../assets/downArrow.png";

interface CustomDropdownProps {
  options: { label: string; value: string }[];
  onSelect: (value: string) => void;
  value?: string;
  isDefault?: boolean;
}

const Dropdown: React.FC<CustomDropdownProps> = ({ options, onSelect, isDefault, value }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState<string>(value || "");

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionClick = (value: string, label: string) => {
    setSelected(label);
    onSelect(value);
    setIsOpen(false);
  };
  // Whenever the value prop changes, update the selected state
  useEffect(() => {
    if (value) {
      setSelected(options.find((option) => option.value === value)?.label || "");
    }
  }, [value, options]);

  const displayedValue = selected || options[0]?.label || "Select option";

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        fontFamily: "Arial, sans-serif",
      }}
    >
      {/* Dropdown Header */}
      <div
        onClick={toggleDropdown}
        style={{
          padding: "8px",
          fontSize: "14px",
          borderRadius: "8px",
          backgroundColor: "#f5f5f5",
          cursor: "pointer",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span
          style={{ color: "#61607E", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", flexGrow: 1 }}
        >
          {displayedValue.length > 45 ? `${displayedValue.slice(0, 35)}...` : displayedValue}
        </span>
        <span style={{ color: "#61607E" }}>{isDefault ? "(Default)" : ""}</span>
        <img src={isOpen ? arrowUp : arrowDown} alt="toggle icon" style={{ width: "20px", height: "20px" }} />
      </div>

      {/* Dropdown Options */}
      {isOpen && (
        <>
          {/* Inline style for invisible scrollbar */}
          <style>
            {`
                .dropdown-options::-webkit-scrollbar {
                width: 8px;
              }
              .dropdown-options::-webkit-scrollbar-track {
                background: #f1f1f1;
                border-radius: 4px;
              }
              .dropdown-options::-webkit-scrollbar-thumb {
                background: #c1c1c1;
                border-radius: 4px;
              }
              .dropdown-options::-webkit-scrollbar-thumb:hover {
                background: #a8a8a8;
              }
              .dropdown-options {
                scrollbar-width: thin;
                scrollbar-color: #c1c1c1 #f1f1f1;
              }
            `}
          </style>
          <div
            className="dropdown-options"
            style={{
              position: "absolute",
              top: "calc(100% + 4px)",
              width: "100%",
              backgroundColor: "#fff",
              color: "#61607E",
              // border: "1px solid #ccc",
              borderRadius: "4px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              zIndex: 1000,
              maxHeight: "200px", // Adjust the height of the dropdown
              overflowY: "auto", // Enable vertical scrolling
              padding: "4px",
            }}
          >
            {options.map((option) => (
              <div
                key={option.value}
                onClick={() => handleOptionClick(option.value, option.label)}
                style={{
                  padding: "8px",
                  fontSize: "14px",
                  cursor: "pointer",
                  backgroundColor: "#fff",
                  borderBottom: "1px solid #f0f0f0",
                  transition: "background-color 0.2s",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  borderRadius: "4px",
                }}
                onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#f5f5f5")}
                onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "#fff")}
              >
                {option.label}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Dropdown;
