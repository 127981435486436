import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { TooltipHost, ITooltipHostStyles } from "@fluentui/react/lib/Tooltip";
import { useId } from "@fluentui/react-hooks";
import logoutIcon from "../../../assets/logout.png";
import logout_selected from "../../../assets/logout_Selected.png";
import logout_hover from "../../../assets/logout_hover.png";
import homeIcon from "../../../assets/home.png";
import home_selected from "../../../assets/home_selected.png";
import home_hover from "../../../assets/home_hover.png";
import { useDispatch } from "react-redux";
import { clearAnalysisData } from "../../store/analysisSlice";

interface SidebarMainProps {
  onLogout: () => void;
}

const calloutProps = { gapSpace: 0 };
const hostStyles: Partial<ITooltipHostStyles> = { root: { display: "inline-block" } };

const SidebarMain: React.FC<SidebarMainProps> = ({ onLogout }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [hovered, setHovered] = useState<string>("");

  const handleIconState = (iconName: string, isHover: boolean = false) => {
    if (isHover) {
      setHovered(iconName);
    } else {
      setHovered("");
    }
  };

  const routeIconMapping: { [key: string]: string } = {
    "/ ": "home",
  };

  const active = routeIconMapping[location.pathname] || "";

  const getIconSrc = (iconName: string, defaultIcon: string, selectedIcon: string, hoverIcon: string) => {
    if (active === iconName) return selectedIcon;
    if (hovered === iconName) return hoverIcon;
    return defaultIcon;
  };

  const menuItems = [
    {
      name: "home",
      label: "Home",
      icon: homeIcon,
      selected: home_selected,
      hover: home_hover,
      route: "/",
    },
    { name: "logout", label: "Logout", icon: logoutIcon, selected: logout_selected, hover: logout_hover, route: "" },
  ];

  return (
    <div
      style={{
        width: "46px",
        minHeight: "100vh",
        backgroundColor: "#D2D2D2",
        color: "white",
        padding: "20px",
      }}
    >
      {menuItems.map((item) => {
        const tooltipId = useId(item.name);

        return (
          <TooltipHost
            key={item.name}
            content={item.label}
            id={tooltipId}
            calloutProps={calloutProps}
            styles={hostStyles}
          >
            <div
              style={{
                marginLeft: "-20px",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                paddingBottom: "15px",
              }}
              onClick={() => {
                handleIconState(item.name);
                if (item.route) {
                  navigate(item.route);
                  if (item.name === "home") {
                    // Clearing Stored risk analysis data when clicked on home button
                    dispatch(clearAnalysisData());
                  }
                }

                if (item.name === "logout") {
                  dispatch(clearAnalysisData());
                  onLogout();
                }
              }}
              onMouseEnter={() => handleIconState(item.name, true)}
              onMouseLeave={() => setHovered("")}
            >
              <img
                src={getIconSrc(item.name, item.icon, item.selected, item.hover)}
                alt={item.label}
                style={{ width: "46px", height: "46px" }}
              />
            </div>
          </TooltipHost>
        );
      })}
    </div>
  );
};

export default SidebarMain;
