/* eslint-disable */
import axios from 'axios';
import * as dotenv from 'dotenv';
dotenv.config();

// Initializing variables
const apiUrl = process.env.LOGIN_API_URL;

const jwtsecret = process.env.REACT_APP_JWT_SECRET;



async function HandleLogin(email: string, password: string) {
    try {
        const payload = { email, password };

        // Making the user login API request
        const response = await axios.post(apiUrl+'/auth/login',payload,
            {
                headers: { 
                    "Content-Type": "application/json",
                },
            }
        );
        return response; 
    } catch (error) {
        throw error;
    }
}



export {HandleLogin }