/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-undef */
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import SidebarMain from "../components/SidebarMain";
import backIcon from "../../../assets/back.png";

interface ReasoningPageProps {
  onLogout: () => void;
}

const ReasoningPage: React.FC<ReasoningPageProps> = ({ onLogout }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { clause } = location.state || {};
  const [key, setKey] = useState(clause?.key || "");
  const [analysis, setAnalysis] = useState(clause?.analysis || "");

  return (
    <div style={{ display: "flex", minHeight: "100vh" }}>
      <SidebarMain onLogout={onLogout} />
      <div
        style={{
          flexGrow: 1,
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          backgroundColor: "white",
        }}
      >
        {/* Back Button */}
        <div
          style={{ display: "flex", alignItems: "center", gap: "8px", cursor: "pointer" }}
          onClick={() => navigate("/analyze")}
        >
          <img src={backIcon} style={{ width: "24px", height: "24px" }} />
          <span style={{ fontSize: "14px", fontStyle: "normal", fontWeight: "400", color: "#424165" }}>Back</span>
        </div>
        {/* KEY TERM HEADING */}
        <div style={{ display: "flex", alignItems: "center", gap: "60px", cursor: "pointer" }}>
          <h1 style={{ fontSize: "16px", fontStyle: "normal", fontWeight: "600", color: "#08081A" }}>AI Reasoning</h1>
        </div>
        {/* Risk */}
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              marginBottom: "0px",
            }}
          >
            <h3 style={{ fontSize: "14px", fontStyle: "normal", fontWeight: "500", color: "#0A0A22" }}>Risk</h3>
          </div>
          <div
            style={{
              width: "100%",
              padding: "16px",
              borderRadius: "8px",
              backgroundColor: " #E7E7EC",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              color: "#61607E",
              overflow: "auto",
              whiteSpace: "pre-wrap", // Ensures text wraps as needed
              wordWrap: "break-word", // Prevents overflow
              height: "auto", // Height adjusts based on content
            }}
          >
            {key}
          </div>
        </div>

        {/* Reason for risk */}
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              marginBottom: "0px",
            }}
          >
            <h3 style={{ fontSize: "14px", fontStyle: "normal", fontWeight: "500", color: "#0A0A22" }}>
              Reason For Risk
            </h3>
          </div>
          <div
            style={{
              width: "100%",
              padding: "16px",
              borderRadius: "8px",
              backgroundColor: " #E7E7EC",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              color: "#424165",
              overflow: "auto",
              whiteSpace: "pre-wrap", // Ensures text wraps as needed
              wordWrap: "break-word", // Prevents overflow
              height: "auto", // Height adjusts based on content
            }}
          >
            {analysis}
          </div>
        </div>

        {/* Chain of Thought */}
        {/* <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              marginBottom: "0px",
            }}
          >
            <h3 style={{ fontSize: "14px", fontStyle: "normal", fontWeight: "500", color: "#0A0A22" }}>
              Chain of Thought
            </h3>
          </div>
          <div
            style={{
              width: "100%",
              padding: "16px",
              borderRadius: "8px",
              backgroundColor: " #E7E7EC",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              color: "#424165",
              overflow: "auto",
              whiteSpace: "pre-wrap", // Ensures text wraps as needed
              wordWrap: "break-word", // Prevents overflow
              height: "auto", // Height adjusts based on content
            }}
          >
            Chain of thought content goes here..
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default ReasoningPage;
