/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-undef */
import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@store/store";
import { setUsergeneratedPlaybook } from "../../store/userDataSlice";
import { switchPlaybook } from "../../services/playbook-service";
import { generatePlaybookFromDocument } from "../../commands/commands";
import { MessageBar, MessageBarType, DefaultButton as Button } from "@fluentui/react";
import Dropdown from "./Dropdown";
import cloudIcon from "../../../assets/cloud.png";
import fileIcon from "../../../assets/file.png";
import SecurtiyIcon from "../../../assets/security.png";
import Typecn from "../../../assets/typcn_info-outline.png";

// import editIcon from "../../../assets/edit.png";
// import trashIcon from "../../../assets/trash.png";
import * as dotenv from "dotenv";
dotenv.config();
import axios from "axios";

const apiUrl = process.env.API_URL;

const UploadPlaybook: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [csvFile, setCsvFile] = useState<File | null>(null);
  const [isCsvFile, setIsCsvFile] = useState<boolean>(false);
  const [contractType, setContractType] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [playbookData, setPlaybookData] = useState<any[]>([]); //to store all playbook data
  const [playbookName, setPlaybookName] = useState<string>(""); //to store playbookname
  const [PlaybookId, setPlaybookId] = useState<string | null>(null); //to store active playbook id
  // const [userId, setUserId] = useState<string | null>(null); //to store the active userid
  const [selectedContractType, setSelectedContractType] = useState<string>("");
  const [selectedClause, setSelectedClause] = useState<string | null>(null);
  const [fileName, setFileName] = useState<string>("");
  const [expanded, setExpanded] = useState<boolean>(false); // State to manage expanded/collapsed view
  const [isGenerated, setIsGenerated] = useState<boolean>(false);

  useEffect(() => {
    if (playbookData.length > 0 && !selectedClause) {
      setSelectedClause(playbookData[0].keyTermId);
    }
  }, [playbookData]);

  const storedUserId = localStorage.getItem("activeUserId");
  const storedPlaybookId = localStorage.getItem("activePlaybookId");

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    //Checking if uploaded file format, if it's CSV then asking user to input the contract type
    if (event.target.files && event.target.files[0].type == "text/csv") {
      setIsCsvFile(true);
      setFileName(event.target.files[0].name);
    }
    if (event.target.files && event.target.files[0]) {
      setError(null); // Clear any previous errors
      const allowedTypes = [
        "text/csv",
        "application/pdf",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ];
      const maxFileSize = 20 * 1024 * 1024;

      // Validate file type
      if (!allowedTypes.includes(event.target.files[0].type)) {
        setError("Only PDF, CSV, and DOCX files are allowed.");
        setCsvFile(null); // Ensure no invalid file is set
        setTimeout(() => setError(null), 3000);
        return;
      }

      // Validate file size
      if (event.target.files[0].size > maxFileSize) {
        setError("File size exceeds the 20MB limit.");
        setCsvFile(null); // Ensure no invalid file is set
        setTimeout(() => setError(null), 3000);
        return;
      }

      setError(null);
      setCsvFile(event.target.files[0]); // If valid, set the file for processing
    }
  };

  const handleUploadButtonClick = () => {
    generatePlaybook();
  };

  useEffect(() => {
    if (csvFile) {
      if (csvFile.type != "text/csv") {
        generatePlaybook();
      }
    }
  }, [csvFile]);

  useEffect(() => {
    const interval = setInterval(() => {
      setUploadProgress((prev) => (prev < 100 ? prev + 10 : prev));
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  const generatePlaybook = async () => {
    setLoading(true);
    setUploadProgress(0); // Reset progress bar
    setIsCsvFile(false);

    const formData = new FormData();
    formData.append("playbookName", csvFile.name);
    formData.append("csv", csvFile);
    formData.append("userId", storedUserId);
    formData.append("playbookType", contractType);

    try {
      // Splitting file format from the string.
      const fileNameParts = csvFile.name.split(".");
      const fileFormat = fileNameParts.length > 1 ? `.${fileNameParts[fileNameParts.length - 1]}` : "";
      let playbookID;
      //for the csv section of files
      if (fileFormat === ".csv") {
        const response = await axios.post(apiUrl + "/api/v1/add-in/generate-playbook", formData, {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadProgress(percentCompleted);
          },
        });
        const responseData = response.data;
        if (responseData.success) {
          playbookID = responseData.data.playbook_id;
          setPlaybookId(responseData.data.playbook_id);
          setPlaybookName(responseData.data.playbook_name);
          setSelectedContractType(responseData.data.contract_type.name);
          setIsGenerated(true);
          localStorage.setItem("activePlaybookName", responseData.data.playbook_name);
          // Dispatch the playbook details to the Redux store
          dispatch(
            setUsergeneratedPlaybook({
              playbookname: responseData.data.playbook_name,
              playbookId: responseData.data.playbook_id,
              active: true,
            })
          );
        } else {
          setError(responseData.error);
          setTimeout(() => setError(null), 5000);
        }
      } else {
        // for non-CSV files
        const mockUploadProgress = setInterval(() => {
          setUploadProgress((prev) => {
            if (prev >= 100) {
              clearInterval(mockUploadProgress);
              return 100;
            }
            return prev + 10;
          });
        }, 200);

        await generatePlaybookFromDocument(csvFile, fileFormat)
          .then((result) => {
            clearInterval(mockUploadProgress); // Clear mock progress
            setUploadProgress(100);
            if (result.success) {
              playbookID = result.data.playbook_id;
              setPlaybookId(result.data.playbook_id);
              setPlaybookName(result.data.playbook_name);
              setSelectedContractType(result.data.contract_type.name);
              localStorage.setItem("activePlaybookName", result.data.playbook_name);
              // Dispatch the playbook details to the Redux store
              dispatch(
                setUsergeneratedPlaybook({
                  playbookname: result.data.playbook_name,
                  playbookId: result.data.playbook_id,
                  active: true,
                })
              );
            } else {
              setError(result.error);
              setTimeout(() => setError(null), 5000);
            }
          })
          .catch((error) => {
            clearInterval(mockUploadProgress); // Clear mock progress on error
            setError(error.message);
            setTimeout(() => setError(null), 5000);
          });
      }
      if (playbookID && storedUserId) {
        localStorage.setItem("activePlaybookId", playbookID);
        await fetchPlaybookTerms(storedUserId, playbookID);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
      setTimeout(() => setError(""), 5000);
    }
  };

  const fetchPlaybookTerms = async (storedUserId, playbookID) => {
    try {
      const getUserPlaybooksResponse = await axios.get(apiUrl + "/api/v1/add-in/get-user-playbooks", {
        params: {
          userId: storedUserId,
          playbookId: playbookID,
        },
      });
      const userPlaybooks = getUserPlaybooksResponse.data.playbookKeys;
      const cleanPlaybookKeys = [];

      // Cleaning playbook key's names. Removing the (number) postfix from the string.
      userPlaybooks.forEach((item) => {
        item.key = item.key.replace(/ \(\d+\)$/, "");
        cleanPlaybookKeys.push(item);
      });

      setPlaybookData(cleanPlaybookKeys);
    } catch (error) {
      console.error("Error fetching user playbooks:", error);
    }
  };

  const resetChoice = () => {
    setError(null);
  };

  const handleClearPlaybook = () => {
    setPlaybookData([]);
    setPlaybookId(null);
    localStorage.removeItem("activePlaybookId");
    localStorage.removeItem("activePlaybookName");
    // dispatch(clearPlaybookData());
  };

  //before proceeding to analyse page checking word has a current opened file, if not setting an errormessage to user
  const checkDocumentContent = async () => {
    if (Office.context.document) {
      await Word.run(async (context) => {
        const docBody = context.document.body;
        docBody.load("text");
        await context.sync();
        if (docBody.text.length > 0) {
          navigate("/analyze");
        } else {
          setError("Please open a standard contract in the Word Application before proceeding.");
        }
      });
    } else {
      setError("Please ensure the Word Add-In is loaded and the document is accessible.");
    }
  };

  const triggerRiskAnalysis = async () => {
    try {
      if (!storedUserId) return;
      const response = await axios.get(apiUrl + "/api/v1/add-in/get-user-playbooks", {
        params: { userId: storedUserId },
      });
      const activePlaybook = response.data.userPlaybooks.find((playbook) => playbook.active === true)?.playbookId;

      if (activePlaybook && activePlaybook === storedPlaybookId) {
        await checkDocumentContent();
      } else {
        // If active playbook is different, switch to the correct playbook
        const switchResponse = await switchPlaybook(storedUserId, storedPlaybookId);
        if (switchResponse) {
          await checkDocumentContent();
        } else {
          setError("Failed to switch playbook.");
        }
      }
    } catch (error) {
      alert("An error occurred while verifying the document state.");
    }
  };

  const contractTypes = [
    { label: "Master Service Agreement", value: "msa" },
    { label: "Non-Disclosure Agreement", value: "nda" },
  ];
  const handleContractTypeChange = (value: string) => {
    setSelectedContractType(value);
  };
  const handleClauseTypeChange = (value: string) => {
    setSelectedClause(value);
  };
  const getStandardValue = (keyterm: string | null) => {
    const selectedPlaybook = playbookData.find((playbook) => playbook.keyTermId === keyterm);
    return selectedPlaybook ? selectedPlaybook.standardAnswer : "No standard value available";
  };
  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const handleContractTypeDropdownChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setContractType(event.target.value);
  };

  return (
    <div style={{ marginTop: "20px", marginBottom: "40px" }}>
      <h2
        style={{
          fontSize: "14px",
          fontWeight: "500",
          fontStyle: "normal",
          marginLeft: "0px",
          color: "#13123E",
        }}
      >
        Upload a playbook document to generate a playbook
      </h2>
      {/* Upload Box */}
      <div
        style={{
          width: "263px",
          // height: "160px",
          height: "auto",
          borderRadius: "8px",
          padding: "10px",
          textAlign: "center",
          backgroundColor: playbookData.length > 0 ? "#effdf4" : "#F9FAFB",
          // backgroundColor: "#F9FAFB",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* Cloud Icon Box */}
        <div
          style={{
            width: "247px",
            height: "43px",
            border: "2px dashed #C3CBD8",
            borderRadius: "8px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#FFFFFF",
            cursor: "pointer",
          }}
          onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "#e6e6e6")}
          onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "#FFFFFF")}
        >
          {/* Hidden File Input */}
          <input
            type="file"
            id="csv"
            accept=".csv, .docx, .pdf"
            required
            style={{
              position: "absolute",
              width: "247px",
              height: "43px",
              opacity: 0,
              cursor: "pointer",
            }}
            onChange={handleFileUpload}
          />
          <img
            src={playbookData.length > 0 ? fileIcon : cloudIcon}
            // src={cloudIcon}
            alt="Upload Icon"
            style={{
              width: "24px",
              height: "24px",
            }}
          />
        </div>

        {/* Upload Message */}
        <p
          style={{
            fontSize: "12px",
            color: "#8A94A6",
            textAlign: "left", // Ensures multiline alignment starts from the same position
            maxWidth: "247px", // Matches the width of the inner box
            marginLeft: "0px",
            paddingTop: "8px",
            marginBottom: "0px",
          }}
        >
          {playbookData.length > 0 ? (
            "Playbook Generated Successfully!"
          ) : (
            <>
              Please upload your playbook in PDF, Docx, or CSV format only.
              <br />
              Max file size 20 MB
              <br />
            </>
          )}
        </p>
        {!isGenerated && (
          <Link
            to="https://devlegalgraph.blob.core.windows.net/app-files/Sample%20CSV%20Playbook.csv"
            style={{ fontSize: "12px", color: "rgb(138, 148, 166)", margin: "10px auto auto 0px" }}
          >
            Sample CSV Format
          </Link>
        )}

        <div style={{ paddingTop: "1rem", width: "100%" }}>
          {isCsvFile && (
            <div>
              <p
                style={{
                  fontSize: "12px",
                  color: "#8A94A6",
                  textAlign: "left",
                  maxWidth: "247px",
                  marginLeft: "0px",
                  paddingTop: "8px",
                  marginBottom: "10px",
                }}
              >
                {playbookData.length > 0 ? (
                  "Playbook Generated Successfully!!!"
                ) : (
                  <>
                    File: <strong>{fileName}</strong> <br /> <br />
                    Please select the contract type for adding the playbook.
                  </>
                )}
              </p>
              <select
                value={contractType}
                onChange={handleContractTypeDropdownChange}
                style={{
                  width: "100%",
                  padding: "8px",
                  fontSize: "14px",
                  borderRadius: "8px",
                  backgroundColor: "rgb(245, 245, 245)",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  margin: "0rem auto 1rem auto",
                }}
              >
                <option value="" disabled>
                  Select Contract Type
                </option>
                <option value="master_service">Master Service Agreement</option>
                {/* <option value="loan">Loan Agreement</option> */}
                <option value="non_disclosure">Non Disclosure Agreement</option>
                <option value="unknown">Other Contracts Playbook</option>
              </select>

              <button
                onClick={handleUploadButtonClick}
                disabled={!contractType}
                style={{
                  width: "100%",
                  padding: "10px",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  backgroundColor: "rgb(62, 81, 238)",
                  color: "rgb(255, 255, 255)",
                  border: "none",
                  borderRadius: "6px",
                  cursor: "pointer",
                }}
              >
                Upload Playbook
              </button>
            </div>
          )}
          {/* Security Info */}
          {/* TODO ITEM: NEED TO WORK ON THIS IN THE NEW UI */}
          {/* <div
            style={{
              marginTop: "1.5rem",
              paddingTop: "1.5rem",
              borderTop: "1px solid #E5E7EB",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "1rem",
              }}
            >
              <img
                src={SecurtiyIcon}
                alt="Secure Connection Icon"
                style={{ width: "1rem", height: "1rem", marginRight: "0.5rem" }}
              />
              <span style={{ fontSize: "0.875rem", fontWeight: "500" }}>Secure Connection</span>
            </div>

            <button
              style={{
                display: "flex",
                alignItems: "center",
                color: "#4B5563",
                background: "none",
                border: "none",
                cursor: "pointer",
              }}
            >
              <img
                src={Typecn}
                alt="Disclaimer Icon"
                style={{ width: "1rem", height: "1rem", marginRight: "0.25rem" }}
              />
              <span style={{ fontSize: "0.875rem" }}>Disclaimer</span>
            </button>

            <p
              style={{
                fontSize: "0.75rem",
                color: "#6B7280",
                lineHeight: "1.5",
              }}
            >
              256-bit AES Encryption. Your files are stored securely in LegalGraph. Your data is not shared with any
              third party, and you can delete your data anytime.
            </p>
          </div> */}
        </div>

        {/* Loading for generate playbook terms */}
        {loading && (
          <div
            style={{
              backgroundColor: "#E5E7EB",
              height: "0.5rem",
              width: "100%", // Ensures full width for the loader container
              borderRadius: "0.25rem",
              overflow: "hidden",
              marginTop: "10px",
              position: "relative", // Ensures child loader bar aligns properly
            }}
          >
            <div
              style={{
                backgroundColor: "#3B82F6",
                height: "100%",
                width: `${uploadProgress}%`,
                transition: "width 0.3s ease-in-out",
                position: "absolute", // Ensures loader bar is contained within parent
                top: 0,
                left: 0,
              }}
            />
          </div>
        )}
      </div>
      {loading && <p>Adding Playbook, Please Wait! This can take up to 30 seconds.</p>}

      {/* Show error message if file type is incorrect */}
      {error && (
        <MessageBar
          messageBarType={MessageBarType.warning}
          isMultiline={false}
          onDismiss={resetChoice}
          dismissButtonAriaLabel="Close"
        >
          {error}
        </MessageBar>
      )}
      {/* Show playbook data  if response available */}
      {playbookData.length > 0 && (
        <>
          <div
            style={{
              width: "262px",
              padding: "10px",
              // backgroundColor: "yellow",
              border: "1px solid #E7E7EC",
              borderRadius: "8px",
              marginLeft: "0px",
              marginTop: "15px",
            }}
          >
            {/* Contract Type */}
            <div style={{ marginBottom: "16px" }}>
              <label
                htmlFor="contractType"
                style={{
                  display: "block",
                  marginBottom: "8px",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#555",
                }}
              >
                Contract Type
              </label>
              <Dropdown options={contractTypes} onSelect={handleContractTypeChange} value={selectedContractType} />
            </div>
            {/* Name of playbook */}
            <div style={{ marginBottom: "16px" }}>
              <label
                htmlFor="partySide"
                style={{
                  display: "block",
                  marginBottom: "8px",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#555",
                }}
              >
                Playbook Name
              </label>
              <div
                style={{
                  width: "100%",
                  padding: "9px",
                  borderRadius: "8px",
                  backgroundColor: "#f5f5f5",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  color: "#61607E",
                  overflow: "hidden", // Hide any overflowed text
                  whiteSpace: "nowrap", // Prevent text from wrapping to the next line
                  textOverflow: "ellipsis", // Add ellipsis when text overflows
                  height: "auto", // Height adjusts based on content
                }}
              >
                {playbookName}
              </div>
            </div>
            {/* select clause */}
            <div style={{ marginBottom: "16px" }}>
              <label
                htmlFor="contractType"
                style={{
                  display: "block",
                  marginBottom: "8px",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#555",
                }}
              >
                Select Clause
              </label>
              <div
                style={{
                  width: "100%",
                  overflowY: "auto",
                  maxHeight: "150px" /* Adjust the height as needed */,
                  // backgroundColor: "#F0F0F0" /* Gray background */,
                  // border: "1px solid #ccc",
                  borderRadius: "4px",
                  padding: "8px",
                }}
              ></div>
              <Dropdown
                options={playbookData.map((playbook) => ({
                  label: playbook.key,
                  value: playbook.keyTermId,
                }))}
                onSelect={handleClauseTypeChange}
              />
            </div>
            <div style={{ marginBottom: "16px" }}>
              <label
                style={{
                  display: "block",
                  marginBottom: "8px",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#555",
                }}
              >
                Standard Value
              </label>
              <div
                style={{
                  width: "100%",
                  padding: "14px",
                  borderRadius: "8px",
                  backgroundColor: "#f5f5f5",
                  fontSize: "14px",
                  color: "#61607E",
                }}
              >
                {selectedClause ? (
                  <>
                    {expanded
                      ? getStandardValue(selectedClause)
                      : `${getStandardValue(selectedClause).substring(0, 150)}...`}
                    <span onClick={toggleExpanded} style={{ color: "#3E51EE", cursor: "pointer" }}>
                      {expanded ? " See Less" : " See More"}
                    </span>
                  </>
                ) : (
                  "No standard value available"
                )}
              </div>
            </div>
          </div>
          {/* Button*/}
          <div style={{ display: "flex", flexDirection: "column", gap: "8px", alignItems: "center" }}>
            <button
              style={{
                width: "75%",
                padding: "10px",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                backgroundColor: "#3E51EE",
                color: "#fff",
                border: "none",
                borderRadius: "6px",
                cursor: "pointer",
                marginTop: "12px",
              }}
              onClick={() => triggerRiskAnalysis()}
            >
              Start Risk Analysis
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default UploadPlaybook;
