/* eslint-disable */
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import { getUserPlaybooks } from "./playbook-service";
import { store } from "../store/store";
import { setAnalysisPayload } from "../store/analysisSlice";
import axios from "axios";
import * as mammoth from "mammoth";
import * as dotenv from "dotenv";
dotenv.config();
// Initializing variables
const apiUrl = process.env.API_URL;

/**
 * Function that takes in a .docx file and returns all the text from it.
 * @param file {File} The .docx file uploaded via an HTML input element.
 * @returns {Promise<string[][]>} Nested array of paragraphs, where each paragraph is split into lines.
 */
async function extractTextFromDocx(docxFile:File) {
  try {
    // Reading the .docx file as an ArrayBuffer
    const arrayBuffer = await docxFile.arrayBuffer();

    // Extracting plain text using Mammoth
    const { value: plainText } = await mammoth.extractRawText({ arrayBuffer });

    // Splitting the text into paragraphs
    const paragraphs = plainText.split(/\n+/).filter((p) => p.trim());

    // Splitting paragraphs further into lines
    const nestedLines = paragraphs.map((paragraph) => [paragraph]);

    return nestedLines;
  } catch (error) {
    throw error;
  }
}

/**
 * Function to take in all the text content from the document > convert it to a PDF.
 * @param text: any, this is the text content from the current open document.
 */
async function convertTextToPdf(textData: any) {
  try {
    const textPdf = await PDFDocument.create();
    const fontSize = 12;
    const margin = 50;
    const lineHeight = fontSize * 1.2; // Add some spacing between lines
    const font = await textPdf.embedFont(StandardFonts.TimesRoman);

    // Create the first page
    let currentPage = textPdf.addPage();
    let { width, height } = currentPage.getSize();
    let currentY = height - margin; // Start from top margin

    // Flatten the nested arrays into a single array of text
    const allText = textData.flat();

    for (const text of allText) {
      // Skip empty lines but maintain spacing
      if (!text.trim()) {
        currentY -= lineHeight;
        continue;
      }

      // Cleaning up text with special characters
      const sanitizedText = sanitizeText(text);

      // Split long text into words for word wrapping
      const words = sanitizedText.split(" ");
      let currentLine = "";

      for (const word of words) {
        // Test if adding this word would exceed page width
        const testLine = currentLine + (currentLine ? " " : "") + word;
        const textWidth = testLine.length * fontSize * 0.6; // Approximate width calculation

        if (textWidth > width - margin * 2) {
          try {
            // Draw current line and move to next line
            currentPage.drawText(currentLine, {
              x: margin,
              y: currentY,
              size: fontSize,
              font: font,
              color: rgb(0, 0, 0),
            });
          } catch (err) {
            // console.warn(`Skipped problematic text: ${currentLine}`);
          }
          currentY -= lineHeight;
          currentLine = word;
        } else {
          currentLine += (currentLine ? " " : "") + word;
        }

        // Check if we need a new page
        if (currentY < margin) {
          currentPage = textPdf.addPage();
          currentY = height - margin;
        }
      }

      // Draw any remaining text
      if (currentLine) {
        try {
          currentPage.drawText(currentLine, {
            x: margin,
            y: currentY,
            size: fontSize,
            font: font,
            color: rgb(0, 0, 0),
          });
        } catch (err) {
          // console.warn(`Skipped problematic text: ${currentLine}`);
        }
        currentY -= lineHeight * 1.5; // Add extra space after paragraphs
      }

      // Check if we need a new page after completing a paragraph
      if (currentY < margin) {
        currentPage = textPdf.addPage();
        currentY = height - margin;
      }
    }

    const pdfBytes = await textPdf.save();
    return new Blob([pdfBytes], { type: "application/pdf" });
  } catch (error) {
    throw error;
  }
}

/**
 * Function to take in all the text content from the document > convert it to a PDF in memory > send it to our backend.
 * @param textData: any, this is the text content from the current open document.
 * @param hasImages: Boolean, this is an identifier if the current contract has images or not.
 */
async function uploadContentAsPdf(textData: any, fileName: string, hasImages: boolean = false, pdfFile: File=null): Promise<any> {
  try {
    let pdfBlob;
    
    // If user directly uploads a pdf to generate a playbook then skip the conversion step.
    if (pdfFile !== null) {
      pdfBlob = pdfFile
    } else {
      // Creating an in-memory PDF from text data
      pdfBlob = await convertTextToPdf(textData);
    }
    const storedUserId = localStorage.getItem("activeUserId");
    // Preparing formData to send into the API
    const formData = new FormData();

    // Building the payload to upload the API.
    formData.append("document", pdfBlob, fileName);
    formData.append("userId", storedUserId);
    formData.append("isAccuracy", "4");
    formData.append("description", "This is a test file for the Django API.");

    // Uploading the file along with payload to the backend.
    const response = await axios.post(apiUrl + "/api/v1/upload-contracts", formData, {
      // TODO ITEM || NEED TO IMPLEMENT BACKEND FIST.
      // headers: {
      //   "Authorization": `Bearer ${localStorage.getItem("authToken")}`, 
      // }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}
/**
 * Function to take in all the text content from the document > convert it to a PDF in memory > send it to our backend.
 * @param textData: any, this is the text content from the current open document.
 * @param hasImages: Boolean, this is an identifier if the current contract has images or not.
 */

async function analyzePlaybook(uploadFileData: any) {
  try {
    // Initializing variables to use within the function.
    const fileData = uploadFileData.fileIds[0];
    let playbookId = "";
    let locationMap = {};
    let missingDataMap = {};

    // Getting active playbook for the user
    const storedUserId = localStorage.getItem("activeUserId");
    const userPlaybooks = await getUserPlaybooks(storedUserId);

    // Getting the active playbookId from all the playbooks
    const activePlaybook = userPlaybooks.find((playbook) => playbook.active);
    playbookId = activePlaybook.playbookId;

    // Preparing payload for backend API.
    const payload = {
      userId: storedUserId,
      playbookId: playbookId,
      fileId: fileData.fileId,
    };

    // Dispatch the payload to Redux
    store.dispatch(setAnalysisPayload(payload));

    // Making the playbook analysis API request
  const response =  await axios.post(apiUrl+'/api/v1/add-in/analyze', payload, {
    // Removed this timeout for now, as backend can take longer to generate response.
    // timeout: 500000,
    // headers: { 
    //     "Authorization": `Bearer ${localStorage.getItem("authToken")}`
    // }
  });
    if(response.data.success === true){
      const responseData =  response.data.data.response;
      responseData.forEach((item) => {
        let cleanData = [];
        
        item.comparison.forEach((comparisonItem, index) => {
          const tempData = {
            key: item.key,
            question: item.question,
            standardAnswer: comparisonItem.standard_answer,
            comment: comparisonItem.comment,
            comparison_type: comparisonItem.comparison_type,
            location: comparisonItem.location || [], // Handle missing location
            UpdatedContext: comparisonItem.UpdatedContext || null, // Handle missing UpdatedContext
            analysis: comparisonItem.analysis || null, // Handle missing analysis
            PartitionKey: item.PartitionKey, // Extract PartitionKey
            FileId: item.FileId, // Extract FileId
          };
    
          cleanData.push(tempData);
    
          // If `comparison_type` is "missing," handle accordingly
          if (comparisonItem.comparison_type === "missing") {
            if (!(item.key in missingDataMap)) {
              missingDataMap[item.key] = [];
            }
            missingDataMap[item.key].push(tempData);
          }
        });
    
        /**
         * Adding clean data into the location map, this data includes Risk Analysis, Key, and Updated context.
         */
        if (!(item.key in locationMap)) {
          locationMap[item.key] = cleanData;
        }
      });
    
      
      return {success: true,locationMap, missingDataMap};
    } else{
      const errorMessage = response.data.message ;
      return { success: false, error: errorMessage };
    }
   
  } catch (error) {
    // throw error;
  }
}

// Internal function to cleanup text for conversion
function sanitizeText(text: string): string {
  if (typeof text !== "string") return "";

  return (
    text
      // Replace common problematic characters
      .replace(/[\u0000-\u0008\u000B-\u000C\u000E-\u001F]/g, "") // Remove control characters
      .replace(/[\u0080-\u009F]/g, "") // Remove C1 control characters
      .replace(/\u0005/g, "") // Specifically remove the ENQ character (0x0005)
      // Replace common Word special characters with their plain text equivalents
      .replace(/[\u2018\u2019]/g, "'") // Smart quotes (single)
      .replace(/[\u201C\u201D]/g, '"') // Smart quotes (double)
      .replace(/[\u2013\u2014]/g, "-") // Em and en dashes
      .replace(/\u2026/g, "...") // Ellipsis
      .replace(/\u00A0/g, " ") // Non-breaking space
      .trim()
  );
}

export { uploadContentAsPdf, analyzePlaybook, extractTextFromDocx ,sanitizeText};
