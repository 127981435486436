/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-undef */
import React, { useState, useEffect, useRef } from "react";
import { RootState } from "@store/store";
import { useDispatch, useSelector } from "react-redux";
import SidebarMain from "../../components/SidebarMain";
import ClauseBox from "../../components/ClauseBox";
// import SearchIcon from "../../../../assets/search.png";
import Loader from "../../components/Loader";
import { setRedlineHeader, setCategorywiseClauses, setActiveData } from "../../../store/analysisSlice";
import { getDocumentMetadata, getPageData } from "../../../services/document-service";
import { analyzePlaybook, uploadContentAsPdf } from "../../../services/upload-to-backend";
import { processLocationData } from "../../../commands/commands";
import { ClauseCategory, AvailableClauses } from "../../../models/analysisData";

interface RiskAnalyseProps {
  onLogout: () => void;
}

const RiskAnalyse: React.FC<RiskAnalyseProps> = ({ onLogout }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [riskData, setRiskData] = useState<ClauseCategory[]>([]); // To hold formatted clause data for heading display
  const [availableClauses, setAvailableClauses] = useState<AvailableClauses>({
    riskClauses: [],
    missingClauses: [],
    favClauses: [],
  });

  const riskData_fromredux = useSelector((state: RootState) => state.analysis.riskData);
  const availableClauses_fromredux = useSelector((state: RootState) => state.analysis.availableClauses);
  const activeData_fromredux = useSelector((state: RootState) => state.analysis.activeData);
  const analysisPayload_fromredux = useSelector((state: RootState) => state.analysis.analysisPayload);

  let loaded = false;
  useEffect(() => {
    const userId = localStorage.getItem("activeUserId");
    if (
      analysisPayload_fromredux?.userId === userId &&
      analysisPayload_fromredux?.fileId === activeData_fromredux?.fileId &&
      analysisPayload_fromredux?.playbookId === activeData_fromredux?.playbookId
    ) {
      setRiskData(riskData_fromredux);
      setAvailableClauses(availableClauses_fromredux);
    } else if (!loaded) {
      loaded = true;
      analyzeContract();
    }
  }, []);

  const analyzeContract = async () => {
    try {
      setIsLoading(true);
      setErrorMessage(null);
      // Getting name of the current open file.
      const fileName = await getDocumentMetadata();

      // Getting all the content from the file.
      const textContent = await getPageData();

      // Calling the upload function to convert the text to PDF and upload it to the backend.
      const convertAndUploadResponse = await uploadContentAsPdf(textContent, fileName);

      // Calling the playbook analysis function
      const playbookAnalysis = await analyzePlaybook(convertAndUploadResponse);

      //based on playbookanalysis response shows in ui
      if (playbookAnalysis.success) {
        const processedData = await processLocationData(playbookAnalysis.locationMap);
        const { categories, formattedData } = countAndFormatClauses(processedData);
        const { PartitionKey, FileId } = processedData[0];
        setRiskData(formattedData);
        setAvailableClauses(categories);

        //redux store
        dispatch(setCategorywiseClauses(categories));
        dispatch(setRedlineHeader(formattedData));
        dispatch(setActiveData({ playbookId: PartitionKey, fileId: FileId }));
      } else {
        setErrorMessage(playbookAnalysis.error);
      }
    } catch (error) {
      setErrorMessage(error?.message || "An unexpected error occurred.");
    } finally {
      setIsLoading(false);
    }
  };

  const countAndFormatClauses = (data) => {
    const categoryCounts = {
      risks: 0,
      missingClauses: 0,
      // favorable: 0,
    };

    // Group clauses by category and count them
    const categories = data.reduce(
      (acc, item) => {
        if (item.category === "contradictory") {
          acc.riskClauses.push(item);
          categoryCounts.risks++;
        } else if (item.category === "missing") {
          acc.missingClauses.push(item);
          categoryCounts.missingClauses++;
        }
        // else if (item.category === "favourable") {
        //   acc.favClauses.push(item);
        //   categoryCounts.favorable++;
        // }
        return acc;
      },
      {
        riskClauses: [],
        missingClauses: [],
        favClauses: [],
      }
    );

    // Prepare the formatted risk data for display
    const formattedData = [];
    if (categoryCounts.risks > 0) {
      formattedData.push({ color: "#F87171", label: `${categoryCounts.risks} Risks` });
    }
    if (categoryCounts.missingClauses > 0) {
      formattedData.push({ color: "#FB923C", label: `${categoryCounts.missingClauses} Missing Clauses` });
    }
    // if (categoryCounts.favorable > 0) {
    //   formattedData.push({ color: "#22C55E", label: `${categoryCounts.favorable} Favourable` });
    // }

    return { categories, formattedData };
  };

  // Function to update the clause count when a user changes the count for any category
  const handleClauseAction = (actionedClause: any, action: "redline" | "reject") => {
    // Update availableClauses by removing the actioned clause
    setAvailableClauses((prevClauses) => {
      const updatedRiskClauses = prevClauses.riskClauses.filter(
        (clause) => clause.location !== actionedClause.location
      );

      return {
        ...prevClauses,
        riskClauses: updatedRiskClauses,
      };
    });

    // Update riskData counts
    setRiskData((prevRiskData) => {
      return prevRiskData.map((risk) => {
        if (risk.color === "#F87171") {
          // Risk color
          const count = parseInt(risk.label.split(" ")[0]);
          return {
            ...risk,
            label: `${count - 1} Risks`,
          };
        }
        return risk;
      });
    });

    // Dispatch to Redux store if needed
    dispatch(
      setCategorywiseClauses({
        ...availableClauses,
        riskClauses: availableClauses.riskClauses.filter((clause) => clause.location !== actionedClause.location),
      })
    );
  };

  if (isLoading) {
    return (
      <div style={{ display: "flex", height: "100vh" }}>
        <SidebarMain onLogout={onLogout} />
        <div
          style={{
            flexGrow: 1,
            padding: "20px",
          }}
        >
          <Loader />
        </div>
      </div>
    );
  }
  if (errorMessage) {
    return (
      <div style={{ display: "flex", height: "100vh" }}>
        <SidebarMain onLogout={onLogout} />
        <div style={{ flexGrow: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>
          <div style={{ textAlign: "center", color: "#F87171" }}>
            <h2>Error</h2>
            <p>{errorMessage}</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div style={{ display: "flex", height: "100vh" }}>
      <SidebarMain onLogout={onLogout} />
      <div style={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
        {/* Riskanalysis search area */}
        <div
          style={{
            position: "sticky",
            top: 0,
            background: "#fff",
            zIndex: 10,
            padding: "1rem",
          }}
        >
          {/* {isSearching ? (
            <input
              type="text"
              placeholder="Search..."
              autoFocus
              style={{
                width: "100%",
                padding: "0.5rem",
                fontSize: "1rem",
                border: "1px solid #B6B6C3",
                borderRadius: "4px",
              }}
              onBlur={() => setIsSearching(false)}
            />
          ) : (
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <h2 style={{ margin: 0, fontSize: "14px", fontWeight: "500", fontStyle: "normal" }}>Risk Level</h2>
              <button
                onClick={() => setIsSearching(true)}
                style={{
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                <img
                  src={SearchIcon}
                  alt="Search"
                  style={{
                    width: "24px",
                    height: "24px",
                  }}
                />
              </button>
            </div>
          )} */}

          <div style={{ marginTop: "1rem" }}>
            {/* Risk color bar */}
            {/* <div
              style={{
                display: "flex",
                height: "17px",
                borderRadius: "8px",
                overflow: "hidden",
                marginBottom: "1rem",
              }}
            >
              <div style={{ flex: 1, backgroundColor: "#F87171" }}></div>
              <div style={{ flex: 1, backgroundColor: "#FB923C" }}></div>
              <div style={{ flex: 2, backgroundColor: "#22C55E" }}></div>
            </div> */}

            {/* Dynamically Render Risk Data */}
            <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
              {riskData.map((risk, index) => (
                <div key={index} style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
                  <span
                    style={{
                      display: "inline-block",
                      width: "10px",
                      height: "10px",
                      backgroundColor: risk.color,
                      borderRadius: "50%",
                    }}
                  ></span>
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: "400",
                      fontStyle: "normal",
                      color: "#9292A6",
                      textDecorationLine: "underline",
                    }}
                  >
                    {risk.label}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* <ClauseBox clauses={availableClauses} onUpdateClauseCount={updateClauseCount} /> */}
        <ClauseBox
          clauses={availableClauses}
          onClauseRedline={(clause) => handleClauseAction(clause, "redline")}
          onClauseReject={(clause) => handleClauseAction(clause, "reject")}
        />
      </div>
    </div>
  );
};

export default RiskAnalyse;
