/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-unescaped-entities */

import React, { ChangeEvent, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { HandleSignup } from "../../../services/signup_backend";

interface SignupInput {
  email: string;
  password: string;
  name: string;
}

interface SignupProps {
  onSignup: () => void;
}

export const Signup: React.FC<SignupProps> = ({ onSignup }) => {
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>("");
  const navigate = useNavigate();

  const [userInputs, setUserInputs] = useState<SignupInput>({
    email: "",
    password: "",
    name: "",
  });

  const [touched, setTouched] = useState({
    email: false,
    password: false,
    name: false,
  });

  useEffect(() => {
    setButtonDisabled(!(userInputs.email && userInputs.password && userInputs.name));
  }, [userInputs]);

  async function sendRequest() {
    setIsLoading(true);
    try {
      // Hardcoded default values for jobRole, company, and phone
      const jobRole = "";
      const company = "";
      const phone = "";

      const response = await HandleSignup(
        userInputs.email,
        userInputs.password,
        userInputs.name,
        jobRole,
        company,
        phone
      );
      const { token, userId } = response.data; // Assuming the response contains token and userId
      localStorage.clear();
      localStorage.setItem("authToken", token);
      localStorage.setItem("activeUserId", userId);
      onSignup(); // Call the onSignup prop to update the authentication state
      navigate("/"); // Redirect to home page after successful signup
    } catch (error) {
      console.error("Signup Error:", error);
      setError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  }

  const handleBlur = (field: keyof SignupInput) => {
    setTouched({ ...touched, [field]: true });
  };

  return (
    <div style={{ height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <div
        style={{ width: "100%", maxWidth: "400px", padding: "2rem", border: "1px solid #ddd", borderRadius: "10px" }}
      >
        <div style={{ textAlign: "center", marginBottom: "20px" }}>
          <h2 style={{ fontSize: "18px", fontWeight: "bold" }}>Create an Account</h2>
          <p style={{ color: "#6b7280" }}>
            Already have an account?
            <Link to="/login" style={{ paddingLeft: "5px", textDecoration: "underline" }}>
              Signin
            </Link>
          </p>
        </div>

        <div style={{ marginTop: "20px" }}>
          <LabelledInput
            label="Name"
            placeholder="Your Name..."
            onChange={(e) => setUserInputs({ ...userInputs, name: e.target.value })}
            onBlur={() => handleBlur("name")}
            error={touched.name && !userInputs.name}
          />
          <LabelledInput
            label="Email"
            placeholder="example@gmail.com"
            onChange={(e) => setUserInputs({ ...userInputs, email: e.target.value })}
            onBlur={() => handleBlur("email")}
            error={touched.email && !userInputs.email}
          />
          <LabelledInput
            label="Password"
            type="password"
            placeholder="********"
            onChange={(e) => setUserInputs({ ...userInputs, password: e.target.value })}
            onBlur={() => handleBlur("password")}
            error={touched.password && !userInputs.password}
          />
          <button
            type="button"
            onClick={sendRequest}
            style={{
              width: "100%",
              marginTop: "20px",
              backgroundColor: "#4b5563",
              color: "white",
              padding: "10px",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              fontSize: "16px",
            }}
            disabled={buttonDisabled || isLoading}
          >
            {isLoading ? "Loading..." : "Sign Up"}
          </button>
          {error && (
            <div
              style={{
                backgroundColor: "#f87171",
                color: "white",
                padding: "5px",
                borderRadius: "5px",
                marginTop: "10px",
              }}
            >
              {error}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

interface LabelledInputType {
  label: string;
  placeholder: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur: () => void;
  type?: string;
  error?: boolean; // New prop to indicate if there's an error
}

function LabelledInput({ label, placeholder, onChange, onBlur, type, error }: LabelledInputType) {
  return (
    <div style={{ marginBottom: "15px" }}>
      <label style={{ display: "block", marginBottom: "5px", fontWeight: "bold", color: "#374151" }}>{label}</label>
      <input
        onChange={onChange}
        onBlur={onBlur}
        type={type || "text"}
        id={label.toLowerCase()}
        style={{
          width: "100%",
          padding: "10px",
          borderRadius: "4px",
          border: error ? "1px solid red" : "1px solid #d1d5db", // Change border color based on error
          fontSize: "14px",
          color: "#374151",
        }}
        placeholder={placeholder}
        required
      />
      {error && <p style={{ color: "red", fontSize: "12px" }}>This field is required</p>} {/* Error message */}
    </div>
  );
}
